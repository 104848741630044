<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    :color="$vuetify.theme.dark ? '' : 'white'"
    flat
    height="75"
    elevation="0"
  >
    <v-toolbar-title class="hidden-sm-and-down font-weight-light" />
    <v-spacer />
    <v-avatar class="ma-3" size="70" tile>
      <v-img
        src="../../assets/589-5891413_free-syria-flag-png-transparent-png.png"
      ></v-img>
    </v-avatar>

    Found syria - المفقوديين السوريين
    <v-avatar class="ma-3" size="70" tile>
      <v-img
        src="../../assets/DALL·E 2024-12-09 16.24.06 - Logo design for a prison named 'Saidnaya', featuring a dominant green color scheme. The logo includes elements like prison bars, guard towers, and a l.webp"
      ></v-img> </v-avatar
    ><v-spacer />

    <div class="mx-3" />
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "appCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text primary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),
  computed: {
    ...mapState(["barColor", "darkMode"]),
    ...mapState(["drawer"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },

  methods: {
    setMode() {
      this.$store.dispatch("changeDarkMode", !this.darkMode);
      this.$vuetify.theme.dark = this.darkMode;
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
  },
};
</script>
