<template>
  <div  >
    <Navbar />
    <v-content>
      <router-view />
    </v-content>
    <v-row align="center" justify="center" class="table-cell">
      <siteFooter class="ma-0" />
    </v-row>
  </div>
</template>

<script>
import Navbar from "@/views/core/Navbar.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import siteFooter from "./components/siteFooter.vue";
export default {
  components: { Navbar, siteFooter },
  name: "dashboard",
  data() {
    return {
      showCookies: false,
    };
  },
  computed: {
    logo() {
      return "<%= BASE_URL %>/../assets/logo.png";
    },
    ...mapState(["darkMode"]),
    mode() {
      return this.$store.getters["darkMode"];
    },
  },

  methods: {},
};
</script>

<style lang="sass">

.agree-privacy
  position: fixed
  bottom: 0px
  width: 100%
  background: rgba(0,0,0,0.7)
  padding: 5px 20px
  box-sizing: border-box
  text-align: center
  z-index: 999999
  h1
    margin-bottom: 5px
    margin-right: 20px

    color: rgba(230,230,230,1)
    font-size: 14px
  p
    margin-bottom: 5px
    margin-right: 20px
    display: inline-block
    color: rgba(230,230,230,1)
    font-size: 14px
    button
      display: inline-block
      font-size: 11px
.fade-up-enter-active
  transition: all .1s
  transition-delay: 1.5s
.fade-up-leave-active
  transition: all .1s
  transition-delay: 1.5s
.fade-up-enter
  transform: translateY(30px)
  opacity: 0
.fade-up-leave-to
  transform: translateY(30px)
  opacity: 0
</style>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
        margin-left: 24px
        margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px
    .v-list-group--sub-group
      .v-list-item
        +ltr()
        padding-left: 8px

        +rtl()
        padding-right: 8px

      .v-list-group__header
        +ltr()
        padding-right: 0

        +rtl()
        padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
          margin-right: 8px

          +rtl()
          margin-left: 8px
.mode
  display: flex
  align-items: center
  justify-content: space-between
  span
    font-size: 14px
</style>