<template>
  <v-container class="site-footer ma-0">
    <v-divider class="mb-5"></v-divider>
    <v-row>
      <v-col cols="12" class="text--secondary text-center  ">
       2024 ©  powered by Mussab Muahimeed
        and
        <a
          target="_blanck"
          class="pa-1 pa-md-0"
          color="primary"
          href="https://pexcode.com"
          title="pexcode | We create stunning digital experiences"
          v-text="'pexcode'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {},
};
</script>

<style>
.site-footer a {
  text-decoration: none;
}
</style>
